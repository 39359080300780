import React from 'react';
import { Home } from '../components/pages/Home';
import { PageLayout } from '../components/layout/PageLayout';

export default function () {
	return (
		<PageLayout>
			<Home />
		</PageLayout>
	);
}
